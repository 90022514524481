import React from 'react'
import styles from './style.module.css'
const Privacy = () => {
    return (
        <div className={styles['Content-Privacy-wrapper']}>
            <div className={styles["Pricay-header"]}>
                <h1>Privacy Policy</h1>
                <p>[Clikgett] ("we," "our," or "us") is committed to protecting the privacy of users ("you" or "your") who access our grocery application.
                    This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application. Please read this policy carefully. If you do not agree with the terms, do not use the app.</p>
            </div>
            <div className={styles['privacy-container']}>

                <div className={styles['privacycontnet']}>
                    <h1>1. *Information We Collect*</h1>
                    <p>We collect several types of information to provide and improve our services, including:</p>
                    <div className={styles["subprivacy-content"]}>
                        <h2> a{")"} *Personal Information*  </h2>
                        <p>When you register or use our app, we may collect the following personal information:</p>
                        <p>- Name</p>
                        <p>- Email address</p>
                        <p>- Phone number</p>
                        <p>- Delivery address</p>
                        <p>- Payment information (e.g., credit card or other payment details)</p>
                        <p>- User login credentials</p>
                    </div>
                    <div className={styles["subprivacy-content"]}>
                        <h2>b{")"} *Automatically Collected Information*  </h2>
                        <p>When you use the app, we automatically collect certain information, such as:</p>
                        <p>- Device Information (e.g., device type, operating system, unique device identifiers) </p>
                        <p>- IP address</p>
                        <p>-- Location data (if enabled)</p>
                        <p>- Usage data (e.g., pages viewed, interactions with features, order history)</p>
                    </div>
                    <div className={styles["subprivacy-content"]}>
                        <h2>c{")"} *Cookies and Tracking Technologies*
                        </h2>
                        <p>We may use cookies, web beacons, and similar tracking technologies to enhance your experience on the app. These technologies help
                            us understand how you interact with the app and allow us to store preferences or track usage trends.</p>
                    </div>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>2. *How We Use Your Information*</h1>
                    <p>We use the information we collect for the following purposes:</p>
                    <p>- *To Provide Services*: To process orders, deliver groceries, and handle customer service.</p>
                    <p>- *To Personalize User Experience*: To personalize your shopping experience, offer product recommendations, and display relevant promotions.</p>
                    <p> - *To Communicate*: To send order confirmations, updates, promotions, and newsletters.</p>
                    <p> - *For Payment Processing*: To process and manage payments for your orders.</p>
                    <p> - *For App Improvement*: To analyze app usage, detect problems, and improve functionality.</p>
                    <p>- *For Legal Obligations*: To comply with applicable laws and regulations.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>3. *How We Share Your Information*</h1>
                    <p>We may share your information in the following situations:</p>
                    <div className={styles["subprivacy-content"]}>
                        <h2> a{")"}  *With Service Providers*  </h2>
                        <p>We share information with third-party service providers that assist us with processing payments, delivering groceries, hosting the app, and performing analytics.</p>
                    </div>
                    <div className={styles["subprivacy-content"]}>
                        <h2> b{")"} *For Legal Purposes*  </h2>
                        <p>We may disclose your information if required by law, to respond to legal processes, or to protect the rights, property, and safety of [Company Name], our users, or others.
                        </p>
                    </div>
                    <div className={styles["subprivacy-content"]}>
                        <h2> c{")"}  *Business Transfers*  </h2>
                        <p>If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of the transaction.
                        </p>
                    </div>
                    <div className={styles["subprivacy-content"]}>
                        <h2> d{")"}  *With Your Consent*  </h2>
                        <p>We may share your information with third parties if you have given your consent to do so.
                        </p>
                    </div>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1> 4. *Data Security*</h1>
                    <p>
                        We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. We use encryption and other security measures to protect sensitive data like payment information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1> 5.*Your Rights*</h1>
                    <p>
                        Depending on your location and applicable data protection laws, you may have the following rights regarding your personal information:
                    </p>
                    <p> - *Access*: Request access to the personal data we hold about you.
                    </p>
                    <p> - *Correction*: Request that we correct any inaccurate or incomplete information.
                    </p>
                    <p> - *Deletion*: Request that we delete your personal information (subject to certain exceptions).
                    </p>
                    <p> - *Data Portability*: Request a copy of your data in a portable format.
                    </p>
                    <p> - *Opt-Out*: Unsubscribe from promotional communications by following the opt-out instructions included in such emails.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>6. *Data Retention*</h1>
                    <p>
                        We retain your personal information for as long as necessary to fulfill the purposes described in this Privacy Policy, including compliance with legal, tax, and accounting requirements.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>7.  *Children's Privacy*</h1>
                    <p>
                        Our services are not directed at individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we discover that a child under 18 has provided us with personal information, we will delete such information.
                    </p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>8.*Third-Party Links*</h1>
                    <p>
                        Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such external sites.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>9. *International Data Transfers*</h1>
                    <p>
                        If you are accessing our app from outside [India], your data may be transferred to and processed in [India], where our servers are located. By using our app, you consent to such transfers.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>10.  *Changes to This Privacy Policy*
                    </h1>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated “Effective Date.” We encourage you to review this policy periodically to stay informed about how we protect your information.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>11.  *Contact Us*
                    </h1>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </p>
                </div>

                <p>Email: [support@clikgett.com]</p>
            </div>
        </div>
    )
}

export default Privacy