import React from 'react'
import styles from '../Privacy/style.module.css'
const Terms = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className={styles['Content-Privacy-wrapper']}>
            <div className={styles["Pricay-header"]}>
                <h1>Terms & Conditions</h1>
                <p>Welcome to [Clikgett]. By accessing or using our mobile application, you agree to comply with and be bound by the following terms and conditions, which govern [Clikgett]’s relationship with you in relation to this app. Please read these terms carefully. If you do not agree with these terms, you should not use the application</p>
            </div>
            <div className={styles['privacy-container']}>

                <div className={styles['privacycontnet']}>
                    <h1>1. *Acceptance of Terms*</h1>
                    <p>
                        By using this application, you agree to these Terms and Conditions, our Privacy Policy,
                        and any additional terms that may apply. You affirm that you are at least 18 years of age or, if under 18, that you have received parental or legal guardian consent
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>2. *Eligibility*</h1>
                    <p>
                        You must be at least 18 years old to register for and use this application. If you are under 18, you may only use the app under the supervision of a parent or legal guardian.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1> 3. *Registration and Account Security*</h1>
                    <p>
                        - You agree to provide accurate, current, and complete information during the registration process
                    </p>
                    <p> - You are responsible for safeguarding your account details and password.
                    </p>
                    <p> - You agree not to share your account with others.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>4. *Order Placement and Delivery*</h1>
                    <p>
                        - *Placing an Order*: Once you place an order through the app, you will receive a confirmation. Please review the details before confirming.
                    </p>
                    <p> - *Order Cancellation*: Orders can be canceled within [specific time frame] after placement.
                    </p>
                    <p> - *Delivery*: We aim to deliver your items within the estimated delivery window. However, we are not responsible for delays due to factors beyond our control (such as traffic, weather, etc.).
                    </p>
                    <p> - *Delivery Fees*: Delivery charges, if any, will be displayed at checkout.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1> 5. *Pricing and Payments*</h1>
                    <p>
                        - *Pricing*: All prices are listed in [Rupees]. Prices may be subject to change without notice. Promotional offers may be available for a limited period only.
                    </p>
                    <p> - *Payments*: We accept payments through [multiple payment methods]. Full payment is required at the time of placing the order.
                    </p>
                    <p> - *Refund Policy*: Refunds will be processed in cases of incorrect or damaged items as per our refund policy [link to refund policy].
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1> 6. *Returns and Refunds*</h1>
                    <p>
                        - *Returns*: Perishable items such as groceries are non-returnable unless there is an issue with the quality.
                        For non-perishable items, returns are accepted within [24 hours] of delivery, subject to our returns policy.
                    </p>
                    <p> - *Refunds*: Refunds will be issued in case of damaged or incorrect items. The refund will be processed to the original payment method within [7] business days.
                    </p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>7. *Prohibited Conduct*</h1>
                    <p>
                        - Misuse of the application for fraudulent purposes.
                    </p>
                    <p> - Attempting to gain unauthorized access to other users’ accounts.
                    </p>
                    <p> - Using the app in any way that violates applicable laws or regulations.</p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>8. *Intellectual Property*</h1>
                    <p>
                        All content on the app, including logos, graphics, and text, is the property of [Clikgett] or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, or distribute any part of the app’s content without prior written consent from [Clikgett].
                    </p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>9. *Limitation of Liability*</h1>
                    <p>
                        To the fullest extent permitted by law, [Clikgett] shall not be liable for any indirect, incidental, or consequential damages arising from your use of the app. Our liability for any claim related to your use of the app shall not exceed the amount you paid for the applicable order.
                    </p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>10. *Indemnification*</h1>
                    <p>
                        You agree to indemnify and hold harmless [Clikgett], its affiliates, and their employees from any claims, damages, or expenses arising from your use of the app or any violation of these terms.
                    </p>
                </div>

                <div className={styles['privacycontnet']}>
                    <h1>11.*Termination*</h1>
                    <p>
                        We may terminate your account and access to the app at our discretion if you violate any of these terms. Upon termination, your right to use the app will immediately cease.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>12. *Governing Law*</h1>
                    <p>
                        These terms are governed by the laws of [Country/State]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of [Country/State].
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>13.*Amendments*</h1>
                    <p>
                        [Clikgett] reserves the right to modify these terms at any time. Any changes will be effective immediately upon posting. Your continued use of the app constitutes your acceptance of the updated terms.
                    </p>
                </div>
                <div className={styles['privacycontnet']}>
                    <h1>14. *Contact Information*</h1>
                    <p>
                        If you have any questions regarding these Terms and Conditions, please contact us at [support@clikgett.com]
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms